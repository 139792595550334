<template>
  <v-container fluid>
    <h4 class="mb-5">{{$_strings.approval.INPROCESS}}</h4>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="itemsTable"
      sort-by="calories"
      class="elevation-1 table"
      item-key="id"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      :server-items-length="totalData"
    >
      <template v-slot:[`item.number`]="{ index }">
        <span>{{getNumber(index)}}</span>
      </template>
      <template v-slot:[`item.process`]="{ item }">
        <a
          @click="handleAction(item.defWorkflowId, item.id)"
        >
          {{item.process}}
        </a>
      </template>
      <template v-slot:[`item.requestDate`]="{ item }">
        <span>{{ dateFormat(item.requestDate) }}</span>
        <p class="grey--text text--darken-2">{{ timeFormat(item.requestDate) }}</p>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <span>{{ formatStatus(item.status) }}</span>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              @click="handleAction(item.defWorkflowId, item.id)"
              color="primary"
              small
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>
                mdi-file-find
              </v-icon>
            </v-btn>
          </template>
          <span>{{$_strings.common.VIEW_DETAIL}}</span>
        </v-tooltip>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
import {
  dateFormat,
  timeFormat,
  handleSortBy,
  handlerPagination,
  defaultPagination,
  statusActionWorkflow,
} from '@/helper/commonHelpers';

export default {
  data() {
    return {
      isLoading: false,
      ListPage: 1,
      totalData: 0,
      headers: [
        {
          text: this.$_strings.common.NUMBER,
          value: 'number',
          sortable: false,
        },
        {
          text: this.$_strings.common.DATE,
          value: 'requestDate',
        },
        {
          text: this.$_strings.common.PROCESS,
          value: 'process',
        },
        {
          text: this.$_strings.contract.header.COMPANY_NAME,
          value: 'companyName',
          width: 200,
        },
        {
          text: this.$_strings.approval.CREATED_AT,
          value: 'requester',
        },
        {
          text: this.$_strings.approval.PIC,
          value: 'activeStepName',
        },
        {
          text: this.$_strings.common.NOTE,
          value: 'notes',
          sortable: false,
        },
        {
          text: this.$_strings.approval.STATUS_APPROVAL,
          value: 'status',
        },
        {
          text: this.$_strings.common.ACTION,
          value: 'actions',
          sortable: false,
        },
      ],
      pagination: defaultPagination(),
      itemsTable: [],
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        this.fetchData();
      },
      deep: true,
    },
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
        cellClass: 'clickable',
      }));
    },
  },
  methods: {
    dateFormat,
    timeFormat,
    async fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage === -1 ? this.totalData : itemsPerPage,
        sort: handleSortBy({ defaultValue: 'requestDate,DESC', sortBy, sortDesc }),
      };
      try {
        this.isLoading = true;
        const result = await this.$_services.approval.getListApprovalInProcesss(filters);
        this.itemsTable = result.data.contents;
        this.totalData = result.data.totalData;
      } finally {
        this.isLoading = false;
      }
    },
    async handleAction(defWorkflow, id, { tab = 0 } = {}) {
      try {
        if (defWorkflow === 15) tab = 1; // TAB 1 IS DOCUMENT SUPPORT
        this.$router.push({
          name: 'skeleton-loaders',
          params: {
            page: 'process',
            id,
            defWorkflow,
          },
          query: {
            tab,
          },
        });
      } catch {
        return false;
      }
    },
    getNumber(index) {
      const x = this.pagination.page * this.pagination.itemsPerPage;
      return x - this.pagination.itemsPerPage + index + 1;
    },
    formatStatus(status) {
      return statusActionWorkflow(status);
    },
  },
};
</script>
